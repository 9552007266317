import React, { useContext } from 'react';
import * as S from './BumpOfferSelectorV1.styles';
import FunnelContext from '../../context/FunnelContext';
import { Image } from '@builder.io/react';

const BumpOfferSelector = props => {
  const {
    bumpOfferIndex = 0,
    hideBumpOfferDiscount,
    bumpOfferPrefixText,
    hideRibbon,
    style = `default`,
    children
  } = props;

  const context = useContext(FunnelContext);

  const {
    currency,
    onetime,
    subscription,
    currentCategory,
    pageDesign,
    bumpOfferChecked,
    setBumpOfferChecked,
    setBumpOfferIndex
  } = context;

  const currencySymbol = currency?.symbol || '$';

  const bumpoffers =
    currentCategory === `onetime`
      ? onetime?.bumpoffers
      : subscription?.bumpoffers;

  const handleBumpOffer = event => {
    setBumpOfferChecked(event.target.checked);
    setBumpOfferIndex(bumpOfferIndex || 0);
  };

  const BumpOfferDiscount = ({ discount_percentage }) => {
    return hideBumpOfferDiscount ? (
      ``
    ) : (
      <span>({discount_percentage}% Off)</span>
    );
  };

  const prefix_text =
    bumpOfferPrefixText && bumpOfferPrefixText !== ``
      ? bumpOfferPrefixText
      : `Enhance Your Chews With Our`;

  const { checkoutData, discount_percentage, display_title } = bumpoffers[
    bumpOfferIndex || 0
  ];

  return (
    <S.BumpOfferWrapper pageDesign={pageDesign} className={style}>
      <S.BumpOffer
        hasChildren={children}
        hideRibbon={hideRibbon}
        className={bumpOfferChecked ? 'active' : ''}
        pageDesign={pageDesign}
      >
        <S.BumpOfferLabel pageDesign={pageDesign} className="label">
          <S.BumpOfferCheckbox
            type="checkbox"
            value={bumpOfferIndex}
            className="checkbox"
            checked={bumpOfferChecked}
            onChange={handleBumpOffer}
          />
          <S.BumpOfferContent pageDesign={pageDesign} className="content">
            {children && children}
            {!children && (
              <>
                <S.BumpOfferImage pageDesign={pageDesign}>
                  <Image alt="bumpoffer" image={checkoutData.image} />
                </S.BumpOfferImage>
                <S.BumpOfferContentText
                  pageDesign={pageDesign}
                  className="content-text"
                >
                  {prefix_text} {display_title} for {currencySymbol}
                  {checkoutData.discounted_price.toFixed(2)}{' '}
                  <BumpOfferDiscount
                    discount_percentage={discount_percentage}
                  />
                </S.BumpOfferContentText>
              </>
            )}
          </S.BumpOfferContent>
        </S.BumpOfferLabel>
      </S.BumpOffer>
    </S.BumpOfferWrapper>
  );
};

export default BumpOfferSelector;
